import httpUtil from '../utils/httpUtil'
import {get} from "@/utils/request";

/**任务管理列表 */

export const queryPage = params => httpUtil.get("/print/task/screen", params);

/**添加视频 */
export const add = params => httpUtil.post("/print/task/screen", params);

/**删除任务 */
export const deleteById = params => httpUtil.delete("/print/task/screen", params);

/**上架视频 */
export const updateData = params => httpUtil.post("/print/task/screen", params);

/** 获取批次号列表 */
export const getTaskNums = params => httpUtil.get("/print/task/getLotNumber", params);

/**编辑 */
export const update = params => httpUtil.put("/print/task/screen", params);

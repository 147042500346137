<template>
    <div class="screenBox">
        <commonTable
                :tableData="tableData"
                :loading="loading"
                @handleSizeChange="handleSizeChange"
                @handleCurrentChange="handleCurrentChange"
                :currentPage="currentPage"
                :total="total"
        >
            <template v-slot:table>
                <el-table-column align="center" type="index" label="序号"/>
                <el-table-column
                        prop="taskName"
                        align="center"
                        label="任务名称"
                        width="100"
                />
                <el-table-column prop="leader" width="120" align="center" label="责任人"/>
                <el-table-column prop="proudctBatch" width="120" align="center" label="产品批次号"/>
                <el-table-column prop="boxBatch" align="center" label="箱标批次号"/>
                <el-table-column prop="boxSpecs" align="center" label="箱标规格"/>
                <el-table-column prop="guestsNum" align="center" label="产品/箱标">
                    <template slot-scope="scope">
                        <span style="color: #000000">{{ scope.row.productRatio }} / {{scope.row.boxRatio}}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="finishNum" width="80" align="center" label="完成数量"/>
                <el-table-column width="180" align="center" label="进度">
                    <template slot-scope="scope">
                        <el-progress :text-inside="true" :stroke-width="15" :percentage="getProcess(scope.row.finishNum,scope.row.num)"></el-progress>
                    </template>
                </el-table-column>
                <el-table-column prop="startTime" align="center" label="开始时间"/>
                <el-table-column prop="endTime"  align="center" label="结束时间"/>
                <el-table-column prop="createTime" align="center" label="创建时间"/>
                <el-table-column align="center" width="100" prop="state" label="状态">
                    <template slot-scope="scope">
                        {{ scope.row.status|taskStatusFilter }}
                    </template>
                </el-table-column>
            </template>
        </commonTable>
    </div>
</template>

<script>
    import commonTable from "@/components/common/commonTable";
    import {mapState} from 'vuex';
    import {
        queryPage,
        add,
        deleteById,
        update,
        getTaskNums,
    } from "@/api/screenTaskManage";
    import {getDataTimeSec} from "@/utils";
    import { taskStatus } from '@/utils/constant'
    export default {
        name: 'screenIndex',
        data() {
            return {
                diagTitle: '添加任务',
                proValue: '',
                proOption: [],
                xbOption: [],
                //
                tableData: [],
                currentPage: 1, //当前页
                pageSize: 10, //显示条数
                loading: false, //表格加载
                total: 0, //总条数
                taskList: [],
                centerDialogVisible: false,
                bEdit: false,
                editUpdate: true,
                isUpdate: true,
                fileList: [],
                userInfo: this.$store.state.loginRoot.userInfo,
                ruleForm: {
                    taskName: '', //任务名称
                    proudctBatch: '', // 产品批次号
                    productRatio: 0, // 产品数
                    boxBatch: '', // 箱标批次号
                    boxRatio: 0, // 箱标数
                    boxSpecs: '',  // 箱子规格
                    leader: '', // 责任人
                },
                rules: {
                    taskName: [{required: true, message: "请输入任务名称", trigger: "blur"}],
                    proudctBatch: [{required: true, message: "请选择产品批次号", trigger: "change"}],
                    productRatio: [{required: true, message: "请输入产品数", trigger: "blur"}],
                    boxBatch: [{required: true, message: "请选择箱标批次号", trigger: "change"}],
                    boxRatio: [{required: true, message: "请输入箱标数", trigger: "blur"}]
                },
            };
        },
        components: {
            commonTable
        },
        filters: {
            taskStatusFilter(type) {
                return taskStatus[type]
            }
        },
        computed: {
            ...mapState(['routerArr']),
            getProcess() {
                return function (val,val2) {
                    if (val == 0) {
                        return 0
                    } else {
                        var num = (val / val2 ) * 100
                        return num
                    }

                }
            }
        },
        watch: {},
        created() {

        },
        mounted() {
            this.queryPage();
        },
        beforeDestroy() {

        },
        methods: {
            async queryPage() {
                let data = {
                    pageSize: this.pageSize,
                    pageNum: this.currentPage
                };
                try {
                    this.loading = true;
                    const result = await queryPage(data);
                    this.loading = false;
                    const { total, list } = result.data;
                    this.tableData = list;
                    this.total = total;
                } catch (error) {
                    this.loading = false;
                }
            },

            handleSizeChange(val) {
                this.pageSize = val;
                this.queryPage();
            },
            handleCurrentChange(val) {
                this.currentPage = val;
                this.queryPage();
            },
        },

    };
</script>

<style lang="scss" scoped>
    .screenBox{
        width: 100%;
        height: 100%;
        position: fixed;
        left: 0;
        top: 0;
        background-color: #fff;
        z-index: 999;
        padding: 20px;
    }
    .avatar-uploader-icon {
        font-size: 28px;
        color: #8c939d;
        width: 78px;
        height: 78px;
        line-height: 180px;
        text-align: center;
    }

    /deep/ .avatar-coverImg .el-upload--text {
        width: 100px;
        height: 100px;
    }

    /deep/ .avatar-coverImg .avatar-uploader-icon {
        line-height: 100px;
    }

    .select-goods-list {
        margin-bottom: 20px;
        border-radius: 40px;
        box-sizing: border-box;
        padding: 0 20px;
        background: #ffffff;
        transition: all 0.4s;
    }

    .select-goods-list:hover {
        background: #f5f5f6;
        transition: all 0.4s;
        cursor: pointer;
    }

    /deep/ .disabled .el-upload--picture-card {
        display: none;
    }
</style>


